<template>
  <v-dialog
      v-model="dialog"
      width="800px"
      hide-overlay
      transition="dialog-top-transition"
      scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>{{ title }} news category</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  v-model="blogCategory.name"
                  :error="$v.blogCategory.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Category name<span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.blogCategory.name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  v-model="blogCategory.bg_color"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Category colour <span class="text-danger"></span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type == 'federal'">
              <v-select
                  label="SCORE"
                  v-model="blogCategory.score_id"
                  :items="scores"
                  item-text="name"
                  item-value="id"
                  clearable
                  outlined
                  dense
              >
              </v-select>
            </v-col>

<!--            <v-col cols="6" md="6">-->
<!--              Is this a main category ?-->
<!--              <v-switch-->
<!--                  v-model="blogCategory.is_parent"-->
<!--                  :label="blogCategory.is_parent?'Main':'Child'"-->
<!--              ></v-switch>-->
<!--            </v-col>-->

            <v-col cols="12" sm="12" md="12" v-if="!blogCategory.is_parent">
              <v-select
                  label="Parent"
                  v-model="blogCategory.parent_id"
                  :error="$v.blogCategory.parent_id.$error"
                  :items="blogCategories"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Parent <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.blogCategory.parent_id.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="blogCategory.description" >
              </ckeditor>
              <span class="text-danger" v-if="$v.blogCategory.description.$error">This information is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="6" md="6">
              Status
              <v-switch
                  v-model="blogCategory.is_active"
                  :label="blogCategory.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
           x-large
            text
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            dark
            x-large
            @click="createOrUpdate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BlogCategoryService from "@/services/cms/blog-category/BlogCategoryService";
import ScoreService from "@/services/score/score/ScoreService";
import {required, numeric, requiredIf} from "vuelidate/lib/validators";

const blogCategory = new BlogCategoryService();
const score = new ScoreService();

export default {
  name: "CreateAndUpdate",
  validations: {
    blogCategory: {
      name: { required },
      parent_id: { required: requiredIf(function () {
          return !this.blogCategory.is_parent;
        }) },
      description: { required },
      is_active: { required }
    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      errors: [],
      blogCategories: [],
      scores: [],
      currentUser: {},
      blogCategory: {
        name:'',
        position: '',
        is_parent: true,
        score_id:'',
        parent_id:'',
        bg_color:'',
        description:'',
        is_active: true,
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
    }
  },
  methods: {
    closeDialog() {
      this.dialog=false;
    },
    createBlogCategory() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editBlogCategory(blogCategory) {
      this.title = 'Update';
      this.dialog = true;
      this.edit = true;
      this.blogCategory = blogCategory;
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAllParent() {
      blogCategory
          .getParent()
          .then(response => {
            this.blogCategories = response.data.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllScore() {
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      this.loading = true;
      blogCategory
          .create(this.blogCategory)
          .then(response => {
            this.$snotify.success("Whats new category added");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update() {
      this.loading = true;
      blogCategory
          .update(this.blogCategory.id,this.blogCategory)
          .then(response => {
            this.$snotify.success("Whats new category updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.getAllParent();
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.dialog = true;
      this.errors = [];
      this.$v.$reset();
      this.blogCategory = {
        name:'',
        position: '',
        is_parent: true,
        score_id:'',
        parent_id:'',
        bg_color:'',
        description:'',
        is_active: true,
      }
    },
  },
  mounted() {
    this.getAllParent();
    this.getAllScore();
    this.getCurrentUser();
  }
}
</script>

<style scoped>

</style>
